<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: 'View'
}
</script>

<style scoped>

</style>
