<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: 'Edit',
  data: () => ({}),
  created () {
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
