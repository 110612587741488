<template>
  <v-container fluid>
    <base-card
        color="indigo"
        icon="mdi-vuetify"
        inline
        class="px-5 py-3"
        title="Справочник контрагентов"
    >
      <v-row>
        <v-col cols="12">
          <v-filter
            :filters="filters"
            :filter-items="filterItems"
            @filterData="filterData"
            @setFilter="resetPage"
          >
          </v-filter>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="footerProps"
        item-key="id"
        dense
      >
        <template v-slot:item.type="{ item }">
          <span>{{ partnerTypeText(item.type) }}</span>
        </template>
        <template v-slot:item.debtor="{ item }">
          <v-icon v-if="item.debtor" color="red">mdi-check-bold</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn icon @click="partnerView(item.id)"><v-icon>mdi-eye</v-icon></v-btn>
          <v-btn icon @click="editPartner(item)"><v-icon>mdi-pencil</v-icon></v-btn>
        </template>
      </v-data-table>
    </base-card>
  </v-container>
</template>

<script>
import pagination from '../../../mixins/pagination'
import VFilter from '../../../components/VFilter'
export default {
  name: 'List',
  mixins: [pagination],
  components: { VFilter },
  data: () => ({
    partners: [],
    orderBy: 'name',
    sortDesc: false,
    partnerType: [
      { value: 'entity', text: 'ЮЛ' },
      { value: 'individual', text: 'ФЛ' }
    ],
    partnerData: {}
  }),
  created () {
    this.getData()
    this.$eventHub.$on('refresh', this.getData)
    this.$eventHub.$on('add', this.createPartner)
  },
  destroyed () {
    this.$eventHub.$off('refresh', this.getData)
    this.$eventHub.$off('add', this.createPartner)
  },
  mounted () {
  },
  computed: {
    headers: function () {
      return [
        { text: 'Тег', value: 'tag' },
        { text: 'Название', value: 'name' },
        { text: 'Дополнительное название', value: 'name_additional' },
        { text: 'Тип', value: 'type' },
        { text: 'Контрагент', value: 'partner.partner', sortable: false },
        { text: 'Контактов', value: 'contact_count', sortable: false },
        { text: 'Дебитор', value: 'debtor', sortable: false },
        { sortable: false, value: 'action' }
      ]
    },
    pagination: {
      get: function () {
        return this.$store.state.partners.pagination
      },
      set: function (value) {
        this.$store.commit('partners/SET_PAGINATION', value)
      }
    },
    filterItems: function () {
      return this.$store.state.partners.filterItems
    },
    filters: {
      get: function () {
        return this.$store.state.partners.filters
      },
      set: function (value) {
        this.$store.commit('partners/SET_FILTERS', value)
      }
    }
  },
  methods: {
    async getData () {
      await this.$store.dispatch('partners/GET_ITEMS')
      this.filterData()
    },
    filterData () {
      this.setParams()
      this.$store.dispatch('partners/GET_PARTNERS', this.getParams).then(response => {
        this.items = response.partners
        this.meta = response.meta
      })
    },
    createPartner () {
      this.$router.push({ name: 'PartnersCreate' })
    },
    editPartner (item) {
      this.$router.push({ path: `/reference/partners/${item.id}/edit` })
    },
    partnerTypeText (type) {
      return this.partnerType.find(item => item.value === type).text
    },
    saveData () {
      if (this.partnerData.partners) delete this.partnerData.partners
      if (this.partnerData.tag || this.partnerData.tag === '') delete this.partnerData.tag
      this.$store.dispatch('partners/POST_PARTNER', this.partnerData)
        .then(() => {
          this.getData()
        })
    },
    partnerView (id) {
      this.$store.commit('rn/VIEW_PARTNER', id)
    }
  }
}
</script>

<style scoped>

</style>
