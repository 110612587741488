<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Клиенты"
    >
      <v-row>
        <v-col cols="12">
          <v-filter
            :filters="filters"
            :filter-items="filterItems"
            @filterData="filterData"
            @setFilter="resetPage"
          >
          </v-filter>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="total"
        :footer-props="footerProps"
        item-key="id"
      >
        <template v-slot:item.birthday="{ item }">
          {{ (item.birthday) ? $moment(item.birthday, 'YYYY-MM-DD').format('L') : '' }}
        </template>
        <template v-slot:item.mobile="{ item }">
          <span
            v-for="(i, key) in item.mobile"
            :key="key"
          >
            {{ i | VMask('+7 (###) ###-####') }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="accent" dark class="mr-2" @click.stop="edit(item)">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
          <v-btn icon color="accent" dark class="mr-2" @click.stop="viewMember(item.id)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </base-card>
    <member-form no-return :member="editMember" create-partner :edit="isEdit"></member-form>
  </v-container>
</template>

<script>
import MemberForm from '../../../components/reference/MemberForm'
import pagination from '../../../mixins/pagination'
import VFilter from '../../../components/VFilter'
export default {
  name: 'List',
  mixins: [pagination],
  components: { VFilter, MemberForm },
  data: () => ({
    sortBy: 'full_name',
    sortDesc: false,
    headers: [
      { text: 'Клиент', value: 'full_name' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Телефон', value: 'mobile', sortable: false, width: '150px' },
      { text: 'Дата рождение', value: 'birthday', sortable: false },
      { value: 'actions', sortable: false }
    ],
    isEdit: false,
    editMember: {
      id: undefined,
      surname: '',
      name: '',
      patronymic: '',
      email: '',
      mobile: [],
      birthday: null,
      prior_comm: undefined
    },
    defaultMember: {
      id: undefined,
      surname: '',
      name: '',
      patronymic: '',
      email: '',
      mobile: [],
      birthday: null,
      prior_comm: undefined
    }
  }),
  created () {
    this.$eventHub.$on('refresh', this.refresh)
    this.$eventHub.$on('add', this.add)
    this.$eventHub.$on('onPostMember', this.onPostMember)
    this.getData()
  },
  destroyed () {
    this.$eventHub.$off('refresh', this.refresh)
    this.$eventHub.$off('add', this.add)
    this.$eventHub.$off('onPostMember', this.onPostMember)
  },
  computed: {
    filterItems: function () {
      return this.$store.state.members.filterItems
    },
    filters: {
      get: function () {
        return this.$store.state.members.filters
      },
      set: function (value) {
        this.$store.commit('members/SET_FILTERS', value)
      }
    },
    pagination: {
      get: function () {
        return this.$store.state.members.pagination
      },
      set: function (value) {
        this.$store.commit('members/SET_PAGINATION', value)
      }
    }
  },
  methods: {
    getData () {
      this.filterData()
    },
    filterData () {
      this.setParams()
      this.$store.dispatch('user/GET_MEMBERS_ONLY', this.getParams).then(response => {
        this.items = response.members
        this.meta = response.meta
      })
    },
    add () {
      this.isEdit = false
      this.editMember = { ...this.defaultMember }
      this.$store.commit('user/OPEN_DIALOG_MEMBER')
    },
    edit (item) {
      this.isEdit = true
      this.editMember = { ...item }
      this.$store.commit('user/OPEN_DIALOG_MEMBER')
    },
    viewMember (id) {
      this.$store.commit('rn/VIEW_MEMBER', id)
    },
    onPostMember () {
      this.refresh()
    },
    refresh () {
      this.filterData()
    }
  }
}
</script>

<style scoped>

</style>
