<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Добавление организации"
    >
      <org-form :fields="fields" @saveData="saveData"></org-form>
    </base-card>
  </v-container>
</template>

<script>
import OrgForm from './OrgForm'
export default {
  name: 'OrgEdit',
  components: { OrgForm },
  data: () => ({
    org_id: null,
    fields: undefined
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
    this.org_id = this.$route.params.id
    this.getItems()
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  methods: {
    getItems () {
      this.$store.dispatch('org/GET_ORG', this.org_id).then(response => {
        if (response.phones.length === 0) response.phones.push('')
        this.fields = response
      })
    },
    saveData ({ fields, create }) {
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('org/PUT_ORG', fields).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Организация сохранена успешно.' })
        if (create) {
          this.$store.commit('partners/CREATE_ORG', response.org)
          this.$router.push({ name: 'PartnersCreate' })
        } else {
          this.$router.push({ path: `/reference/org/${response.org.id}/view` })
        }
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
