<template>
  <v-dialog v-model="dialog" persistent max-width="560px">
    <v-form
      ref="bank_form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>Банковские реквизиты</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="bank_data.name"
                dense
                label="Название реквизитов"
                :rules="ruleRequired"
              ></v-text-field>
              <v-text-field
                v-model="bank_data.bik"
                dense
                label="БИК"
                :rules="rulesBik"
              ></v-text-field>
              <v-text-field
                v-model="bank_data.bank_name"
                dense
                label="Название банка"
                :rules="ruleRequired"
              ></v-text-field>
              <v-text-field
                v-model="bank_data.checking_account"
                dense
                label="Р/с"
                :rules="rulesAccount"
              ></v-text-field>
              <v-text-field
                v-model="bank_data.corr_account"
                dense
                label="К/с"
                :rules="rulesAccount"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="error" @click="closeBankDialog">Отменить</v-btn>
          <v-btn small color="success" :disabled="!valid" @click="addBank">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'BankForm',
  props: {
    bank_data: {
      type: Object,
      default: function () {
        return {
          name: '',
          checking_account: '',
          corr_account: '',
          bik: '',
          bank_name: ''
        }
      }
    }
  },
  data: () => ({
    valid: true,
    ruleRequired: [v => !!v || 'Обязательное поле'],
    rulesAccount: [
      v => !!v || 'Обязательное поле',
      v => (v && v.toString().length === 20) || 'Длина счета должна содержать 20 цифр',
      v => /\d{20}$/.test(v) || 'Номер счета должен содержать 20 цифр'
    ],
    rulesBik: [
      v => !!v || 'Обязательное поле',
      v => (v && v.toString().length === 9) || 'Длина БИК должна содержать 9 цифр',
      v => /\d{9}$/.test(v) || 'БИК должен содержать 9 цифр'
    ]
  }),
  created () {
  },
  mounted () {
  },
  computed: {
    dialog: function () {
      return this.$store.state.org.dialog.bank
    }
  },
  methods: {
    addBank () {
      if (!this.$refs.bank_form.validate()) {
        return
      }
      this.$emit('pushBank')
      this.closeBankDialog()
    },
    closeBankDialog () {
      this.bank_data = { name: '', checking_account: '', corr_account: '', bik: '', bank_name: '' }
      this.$store.commit('org/CLOSE_FORM', 'bank')
    }
  }
}
</script>

<style scoped>

</style>
