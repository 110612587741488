<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Добавление объекта недвижимости"
    >
      <fias-picker @btnSelect="btnSelect"></fias-picker>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="facility.address"
            :disabled="disable"
            label="Адрес"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-if="flatsCount"
            v-model="flatsCount"
            :disabled="true"
            label="Количество помещений (квартир) по ФИАС"
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="disable">
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="facility.name"
              label="Название"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="facility.type"
              :items="facilityType"
              label="Тип объекта"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="8">
            <v-data-iterator
              :items="facility.block"
              :items-per-page="12"
              hide-default-footer
            >
              <template v-slot:header>
                <v-btn text outlined color="success" @click.stop="addBlock" :disabled="addBlockDisabled">Добавить блок</v-btn>
              </template>
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    cols="12" lg="3"
                    v-for="(item, idx) in props.items"
                    :key="idx"
                  >
                    <v-card>
                      <v-card-title class="font-weight-bold d-flex justify-space-between">
                        Блок: {{ idx+1 }}
                        <v-btn v-if="idx > 0" icon @click="deleteBlock(idx)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field v-model="item.name" label="Номер блока"></v-text-field>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="item.floors" label="Начальный этаж" type="number"
                              v-tooltip.bottom="`Начальный этаж с учетом техэтажей`"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="item.floors_end" label="Конечный этаж" type="number"
                              v-tooltip.bottom="`Конечный этаж с учетом техэтажей`"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-switch
                          v-model="item.not_zero"
                          v-tooltip.bottom="`Нулевой этаж будет пропущен`"
                          label="Пропустить нулевой этаж"
                        ></v-switch>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col cols="8" class="d-flex align-center">
                            <span>Номера помещений:</span>
                          </v-col>
                          <v-col cols="4">
                            <v-btn
                                icon
                                :disabled="!(item.name && item.floors)"
                                v-tooltip.bottom="`Назначить все квартиры в блок`"
                            ><v-icon color="success" @click="connectAllRoomByBlock(idx)">mdi-check-all</v-icon></v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field v-model="item.room_start" dense type="number"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="item.room_end" dense type="number"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-btn
                              icon
                              :disabled="!(item.room_start && item.room_end && item.name && item.floors)"
                            ><v-icon color="success" @click="connectRoomByBlock(idx)">mdi-check</v-icon></v-btn>
                          </v-col>
                        </v-row>
                        <v-combobox v-model="item.entrance" label="Подъезды" multiple chips></v-combobox>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
          <v-col cols="4">
            <v-data-table
              v-model="flatsSelect"
              :headers="roomsTable"
              :items="flats"
              item-key="ROOMGUID"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-select
              @toggle-select-all="selectAllRooms"
            >
              <template v-slot:item.block="{ item }">
                <v-select
                  v-model="item.block"
                  :items="listBlock"
                  clearable
                ></v-select>
              </template>
<!--              <template v-slot:item.entrance="{ item }">-->
<!--                <v-select-->
<!--                  v-model="item.entrance"-->
<!--                  :items="listEntrance"-->
<!--                  clearable-->
<!--                  :disabled="!item.block"-->
<!--                ></v-select>-->
<!--              </template>-->
              <template v-slot:item.floor="{ item }">
                <v-select
                  v-model="item.floor"
                  :items="listFloor(item)"
                  clearable
                  :disabled="!item.block"
                ></v-select>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-btn color="success" @click.stop="btnCreate">Сохранить</v-btn>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import FiasPicker from '../../../components/reference/facility/FiasPicker'
export default {
  name: 'Create',
  components: { FiasPicker },
  data: () => ({
    facility: {
      name: '',
      address: '',
      uuid: '',
      type: '',
      passport: '',
      block: [
        {
          name: '1',
          floors: null,
          floors_end: null,
          not_zero: false,
          entrance: [],
          room_start: null,
          room_end: null
        }
      ]
    },
    flats: [],
    flatsCount: null,
    disable: false,
    roomsTable: [
      { text: 'Тип', value: 'flat_type.NAME' },
      { text: 'Номер', value: 'FLATNUMBER' },
      { text: 'Блок', value: 'block', sortable: false },
      // { text: 'Подъезд', value: 'entrance', sortable: false },
      { text: 'Этаж', value: 'floor', sortable: false }
    ],
    sortBy: 'FLATNUMBER',
    sortDesc: false,
    flatsSelect: [],
    facilityType: [
      { value: 'business', text: 'Бизнес центр' },
      { value: 'trading', text: 'Торговый центр' },
      { value: 'street', text: 'Street Retail' },
      { value: 'housing', text: 'Жилой комплекс' }
    ]
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  mounted () {
  },
  computed: {
    addBlockDisabled: function () {
      return this.facility.block.length >= 12
    },
    listBlock: function () {
      const block = []
      this.facility.block.forEach(function (item, idx, array) {
        if (item.name) {
          block.push(item.name)
        }
      })
      return block
    },
    listEntrance: function () {
      let entrances = []
      this.facility.block.forEach(function (item, idx, array) {
        entrances = entrances.concat(item.entrance)
      })
      return entrances
    }
  },
  methods: {
    btnSelect (value) {
      this.facility.address = value.address
      this.facility.uuid = value.guid
      if (!this.facility.address || !this.facility.uuid) {
        this.disable = false
        return
      }
      this.disable = true
      this.$store.dispatch('facility/GET_FLAT_COUNT', { guid: value.guid })
        .then(response => {
          this.flatsCount = response.length
          this.flats = response
        })
    },
    addBlock () {
      if (this.facility.block.length < 12) {
        this.facility.block.push(
          {
            name: String(this.facility.block.length + 1),
            floors: null,
            entrance: [],
            room_start: null,
            room_end: null,
            floors_end: null,
            not_zero: false
          }
        )
      }
    },
    deleteBlock (idx) {
      this.facility.block.splice(idx, 1)
    },
    selectAllRooms (val) {
      if (val.value) {
        this.flatsSelect = this.flats.slice()
      } else {
        this.flatsSelect = []
      }
    },
    btnCreate () {
      this.$store.dispatch('facility/POST_FACILITY', { facility: this.facility, rooms: this.flatsSelect })
        .then(response => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Объект успешно создан.' })
          this.$router.push({ path: `/reference/facility/${response}/view` })
        })
    },
    listFloor: function (room) {
      let list = []
      if (room.block !== null && room.block !== undefined) {
        const block = this.facility.block.find(item => item.name === room.block)
        if (block /* this.facility.block[room.block].floors */) {
          list = Array(parseInt(/* this.facility.block[room.block] */ block.floors)).fill(null).map((_, i) => i + 1)
        }
      } /* else {
        this.facility.block.forEach(function (item, idx, array) {
          if (item.entrance && item.entrance.indexOf(room.entrance) !== -1) {
            list = Array(parseInt(item.floors)).fill(null).map((_, i) => i + 1)
          }
        })
      } */
      return list
    },
    connectRoomByBlock: function (idx) {
      const block = this.facility.block[idx]
      let room
      if (block.room_end < block.room_start) return
      for (let i = Number(block.room_start); i <= Number(block.room_end); i++) {
        room = this.flats.find(item => Number(item.FLATNUMBER) === i)
        if (room) {
          room.block = block.name
        }
      }
    },
    connectAllRoomByBlock: function (idx) {
      const self = this
      console.log(self.facility)
      this.flats.forEach(function (item, i, array) {
        item.block = self.facility.block[idx].name
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
