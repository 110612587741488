<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Добавить контрагента"
    >
      <partner-form @saveData="saveData"></partner-form>
    </base-card>
  </v-container>
</template>

<script>
import PartnerForm from './PartnerForm'
export default {
  name: 'Create',
  components: { PartnerForm },
  data: () => ({
    partnerData: {
      type: undefined,
      name: '',
      name_additional: '',
      org_id: undefined,
      user_id: undefined,
      is_active: true,
      contact_person: [],
      service_object: []
    }
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  methods: {
    saveData (item) {
      this.$store.commit('OVERLAY_ON')
      if (item.partners) delete item.partners
      if (item.tag || item.tag === '') delete item.tag
      this.$store.dispatch('partners/POST_PARTNER', item).then(() => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Контрагент успешно сохранен.' })
        this.$router.push({ name: 'PartnersList' })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
