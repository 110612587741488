<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Справочники"
    >
      <v-toolbar flat dense>
        <v-toolbar-title>
          <v-tabs v-model="tab" @change="tabsChange">
            <v-tab
              v-for="item in itemsTab"
              :key="item.value"
            >{{ item.name }}</v-tab>
          </v-tabs>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="editDialog" max-width="560px" persistent v-if="[0, 1, 2].indexOf(tab)!==-1">
          <template v-slot:activator="{ on, attr }">
            <v-btn color="primary" small dark class="mb-2" v-bind="attr" v-on="on">Добавить</v-btn>
          </template>
          <v-card>
            <v-card-title><span class="headline">{{ formTitle }}</span></v-card-title>
            <component :is="formComponent" :editedItem="editedItem"></component>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="crudClose">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="crudSave">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in itemsTab"
          :key="item.value"
        >
          <template v-if="['group'].indexOf(item.value) === -1">
            <v-data-table
              :headers="computedHeader"
              :items="items"
            >
              <template v-slot:item.action="{ item }">
                <v-btn icon @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              </template>
              <template v-slot:item.params.no_reports="{ item }">
                <span flat class="d-flex justify-center">
                  <v-icon v-if="item.params.no_reports" color="red">mdi-close</v-icon>
                </span>
              </template>
              <template v-slot:item.params.manual="{ item }">
                <v-chip>{{ fieldManual(item) }}</v-chip>
              </template>
              <template v-slot:item.params.type="{ item }">
                {{ fieldType(item) }}
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="6">
                <v-data-table
                  v-model="selectedGroup"
                  :headers="headersGroup"
                  :items="itemsGroup"
                  single-select
                  show-select
                  @input="inputClick"
                  @click:row="rowClick"
                ></v-data-table>
              </v-col>
              <v-col cols="6">
                <v-data-table
                  :headers="headersSubgroup"
                  :items="itemsSubgroup"
                ></v-data-table>
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    tab: null,
    headersGroup: [
      { text: 'Наименование группы', value: 'name' }
    ],
    headersSubgroup: [
      { text: 'Наименование подгруппы', value: 'name' }
    ],
    items: [],
    itemsGroup: [],
    itemsSubgroup: [],
    selectedGroup: [],
    itemsTab: [
      { value: 'legal_form', name: 'Правовая форма' },
      { value: 'declarer', name: 'Заявитель' },
      { value: 'type', name: 'Тип услуги' },
      { value: 'channel', name: 'Канал поступления' },
      { value: 'group', name: 'Группы заявок' }
    ],
    fieldTypeText: [
      { value: 'email', text: 'E-mail' },
      { value: 'messanger', text: 'Мессенджер' },
      { value: 'mobile_app', text: 'Мобильное приложение' },
      { value: 'site', text: 'Сайт' },
      { value: 'landing', text: 'Лендинг' },
      { value: 'social', text: 'Социальная сеть' }
    ],
    editDialog: false,
    editedItem: {
      name: '',
      description: '',
      params: null
    },
    defaultItem: {
      name: '',
      description: '',
      params: null
    },
    editedIndex: -1,
    formName: 'FormCommon'
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    formTitle: function () {
      return this.editedIndex === -1 ? 'Добавить элемент' : 'Редактировать элемент'
    },
    formComponent: function () {
      const form = this.formName
      return () => import(`./form/${form}`)
    },
    computedHeader: function () {
      let header = [
        { text: 'Наименование', value: 'name' },
        { text: 'Описание', value: 'description' },
        { value: 'action' }
      ]
      switch (this.tab) {
        case 2:
          header = [
            { text: 'Наименование', value: 'name' },
            { text: 'Описание', value: 'description' },
            { text: 'Плановый срок выполнения', value: 'params.time' },
            { text: 'Не включать в отчет', value: 'params.no_reports' },
            { value: 'action' }
          ]
          break
        case 3:
          header = [
            { text: 'Наименование', value: 'name' },
            { text: 'Описание', value: 'description' },
            { text: 'Вручную', value: 'params.manual' },
            { text: 'Код', value: 'params.type' }
            // { value: 'action' }
          ]
      }
      return header
    }
  },
  methods: {
    getData (type) {
      this.$store.dispatch('reference/GET_REFERENCE', type)
        .then(response => {
          this.items = response.data
          this.defaultItem = response.fields
          this.editedItem = response.fields
        }).catch(error => {
          const message = error.response.data.message || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        })
    },
    getGroups () {
      this.$store.dispatch('reference/GET_REFERENCE', 'groups')
        .then(response => {
          this.itemsGroup = response.data
          this.defaultItem = response.fields
          this.editedItem = response.fields
        }).catch(error => {
          const message = error.response.data.message || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        })
    },
    tabsChange (item) {
      if (this.itemsTab[item].value === 'group') {
        this.getGroups()
      } else {
        this.getData(this.itemsTab[item].value)
      }
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },
    crudClose () {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    crudSave () {
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('reference/POST_REFERENCE', { type: this.itemsTab[this.tab].value, payload: this.editedItem })
        .then(response => {
          this.$set(this, 'items', response)
        }).catch(error => {
          const message = error.response.data.message || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
      this.crudClose()
    },
    fieldManual (item) {
      return item.params?.manual ? 'Да' : 'Нет'
    },
    fieldType (item) {
      if (!item.params?.type) return ''
      const type = this.fieldTypeText.find(i => i.value === item.params.type)
      if (!type) return ''
      return type.text
    },
    inputClick (event) {
      if (event.length === 0) {
        this.itemsSubgroup = []
        return
      }
      this.itemsSubgroup = this.selectedGroup[0].subgroup
    },
    rowClick (event) {
      this.selectedGroup = [{ ...event }]
      this.itemsSubgroup = this.selectedGroup[0].subgroup
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  watch: {
    tab (val) {
      switch (val) {
        case 0:
        case 1:
          this.formName = 'FormCommon'
          break
        case 2:
          this.formName = 'FormTypeService'
      }
    }
  }
}
</script>

<style scoped>

</style>
