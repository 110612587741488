<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Объект недвижимости"
    >
      <v-row>
        <v-col cols="8">
          <span class="font-weight-bold mr-3">Адрес:</span>{{ facility.address }}
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="8">
            <span class="font-weight-bold mr-3">Название:</span>{{ facility.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <span class="font-weight-bold mr-3">Тип:</span>{{ facilityTypeText }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" lg="7">
            <v-data-iterator
              :items="facility.block"
              :items-per-page="12"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    cols="12" lg="3"
                    v-for="(item, idx) in props.items"
                    :key="idx"
                  >
                    <v-card>
                      <v-card-title class="font-weight-bold d-flex justify-space-between">
                        <span>Блок: {{ item.name }}</span>
                        <v-btn
                          icon
                          @click="clickSelectBlock(idx)"
                          :color="(selectBlock == idx) ? 'success' : 'primary'"
                        ><v-icon>mdi-eye</v-icon></v-btn>
                      </v-card-title>
                      <v-card-text>
                        <span class="font-weight-bold mr-3">Этажей:</span>{{ item.floors }}
                        <v-divider class="my-2"></v-divider>
                        <span class="font-weight-bold mr-3">Подъезды:</span>{{ item.entrance.map(a => a.number).join(', ') }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
          <v-col cols="12" lg="5">
            <v-toolbar flat dense>
              <v-toolbar-title>
                <v-tabs v-model="tab">
                  <v-tab>Помещения</v-tab>
                  <v-tab>Этажи</v-tab>
                </v-tabs>
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="crudDialog" max-width="560px" persistent v-if="tab===0">
                <template v-slot:activator="{ on, attr }">
                  <v-btn color="primary" small dark class="mb-2" v-bind="attr" v-on="on" @click="crudAdd">Добавить</v-btn>
                </template>
                <v-card>
                  <v-card-title><span class="headline">{{ formFlatTitle }}</span></v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.type"
                            :items="flatType"
                            item-value="type"
                            item-text="text"
                            label="Тип"/>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            v-model="editedItem.block_id"
                            :items="facility.block"
                            item-value="id"
                            item-text="name"
                            label="Блок"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            v-model="editedItem.floor_id"
                            :items="floor"
                            item-value="id"
                            item-text="number"
                            label="Этаж" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model.trim="editedItem.name"
                            label="Номер"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-number
                            v-model.trim="editedItem.area"
                            label="Площадь"/>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.bti"
                            label="Номер БТИ" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.description"
                            label="Описание" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="crudClose">Отмена</v-btn>
                    <v-btn color="blue darken-1" text @click="crudSave">Сохранить</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="floorDialog" max-width="560px" persistent v-if="tab===1">
                <template v-slot:activator="{ on, attr }">
                  <v-btn color="primary" small dark class="mb-2" v-bind="attr" v-on="on">Добавить</v-btn>
                </template>
                <v-card>
                  <v-card-title><span class="headline">{{ formFloorTitle }}</span></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-form ref="floor_form">
                          <v-text-field
                            v-model="editedFloorItem.number"
                            label="Номер этажа"
                            type="number"/>
                          <v-select
                            v-model="editedFloorItem.type"
                            :items="floorType"
                            item-value="type"
                            item-text="text"
                            label="Тип этажа"/>
                          <v-file-input
                            v-model="planImage"
                            label="План этажа"
                            chips
                          />
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="floorCrudClose">Отмена</v-btn>
                    <v-btn color="blue darken-1" text @click="floorCrudSave">Сохранить</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="deleteDialog" max-width="500px" persistent>
                <v-card>
                  <v-card-title class="headline">Действительно удалить?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Нет</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Да</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table
                  :headers="flatsTable"
                  :items="flats"
                  item-key="id"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  dense
                >
                  <template v-slot:item.block>
                    <span v-html="block"></span>
                  </template>
                  <template v-slot:item.type="{ item }">
                    <span>{{ flatTypeText(item.type) }}</span>
                  </template>
                  <template v-slot:item.partner="{ item }">
                    {{ firstPartner(item) }}
                    <v-menu
                      v-if="item.s_object.length > 1"
                      v-model="item.menu"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-menu-down-outline</v-icon></v-btn>
                      </template>
                      <v-card>
                        <v-list>
                          <v-list-item
                            v-for="(i, key) in otherPartners(item)" :key="key"
                          >
                            <v-list-item-content>{{ i.partner.name }}</v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </template>
                  <template v-slot:item.floor="{ item }">
                    <v-select
                      v-model="item.floor_id"
                      :items="floor"
                      item-value="id"
                      item-text="number"
                      @change="changeFloor(item)"
                      dense
                    ></v-select>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
                    <v-icon small @click="viewFlatItem(item)">mdi-eye</v-icon>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="floorTable"
                  :items="floor"
                  item-key="id"
                >
                  <template v-slot:item.type="{ item }">
                    <v-select
                      v-model="item.type"
                      :items="floorType"
                      item-value="type"
                      item-text="text"
                      @change="changeTypeFloor(item)"
                    ></v-select>
                  </template>
                  <template v-slot:item.plan="{ item }">
                    <span v-if="!item.plan">Нет</span>
                    <span v-else><v-btn icon @click="openPlan(item.plan)"><v-icon>mdi-floor-plan</v-icon></v-btn></span>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon small class="mr-2" @click="editFloorItem(item)">mdi-pencil</v-icon>
                    <v-icon small>mdi-eye</v-icon>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </base-card>
    <v-plan :path-plan="showPlan" @closePlan="showPlan = null"></v-plan>
  </v-container>
</template>

<script>
import VPlan from '../../../components/reference/floor/VPlan'
import VNumber from '../../../components/VNumber'
export default {
  name: 'View',
  components: { VNumber, VPlan },
  data: () => ({
    facility_id: null,
    facility: {},
    flatsTable: [
      { text: 'Тип', value: 'type', divider: true },
      { text: 'Номер', value: 'name', divider: true },
      { text: 'Блок', value: 'block', divider: true },
      { text: 'Контрагент', value: 'partner', divider: true },
      { text: 'Этаж', value: 'floor', width: '15%', divider: true },
      { text: '', value: 'action', sortable: false, align: 'center', width: '96px' }
    ],
    floorTable: [
      { text: 'Этаж', value: 'number', divider: true, align: 'center', width: '25%' },
      { text: 'Тип', value: 'type', divider: true, align: 'center', width: '25%' },
      { text: 'План', value: 'plan', divider: true, align: 'center', width: '25%' },
      { text: '', value: 'action', sortable: false, align: 'center', width: '25%' }
    ],
    sortBy: 'name',
    sortDesc: false,
    selectBlock: 0,
    flatType: [
      { type: 'flat', text: 'Квартира' },
      { type: 'office', text: 'Офис' },
      { type: 'not_residential', text: 'Нежилое' },
      { type: 'parking_place', text: 'Машиноместо' },
      { type: 'mop', text: 'МОП' }
    ],
    facilityType: [
      { type: 'business', text: 'Бизнес центр' },
      { type: 'trading', text: 'Торговый центр' },
      { type: 'street', text: 'Street Retail' },
      { type: 'housing', text: 'Жилой комплекс' }
    ],
    floorType: [
      { type: 'residential', text: 'Жилой' },
      { type: 'basement', text: 'Подвал' },
      { type: 'parking', text: 'Паркинг' },
      { type: 'tech', text: 'Техэтаж' }
    ],
    editedItem: {
      type: undefined,
      name: '',
      block_id: undefined,
      floor_id: undefined,
      description: null,
      bti: null
    },
    defaultItem: {
      type: undefined,
      name: '',
      block_id: undefined,
      floor_id: undefined,
      description: null,
      bti: null
    },
    editedFloorItem: {
      number: undefined,
      type: undefined
    },
    defaultFloorItem: {
      number: undefined,
      type: undefined
    },
    crudDialog: false,
    deleteDialog: false,
    floorDialog: false,
    editedIndex: -1,
    editedFloorIndex: -1,
    tab: null,
    planImage: null,
    showPlan: null
  }),
  created () {
    this.$eventHub.$on('refresh', this.getData)
    this.$eventHub.$on('back', this.goBack)
    this.facility_id = Number(this.$route.params.id)
    this.getData()
  },
  destroyed () {
    this.$eventHub.$off('refresh', this.getData)
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    flats: function () {
      if (this.facility && this.facility.block) {
        return this.facility?.block[this.selectBlock]?.flats
      } else {
        return []
      }
    },
    floor: function () {
      if (this.facility.block) {
        return this.facility?.block[this.selectBlock]?.floor
      } else {
        return []
      }
    },
    block: function () {
      if (this.facility.block) {
        return this.facility?.block[this.selectBlock]?.name
      } else {
        return ''
      }
    },
    facilityTypeText: function () {
      const type = this.facilityType.find(item => item.type === this.facility.type)
      if (type) {
        return type.text
      } else {
        return ''
      }
    },
    formFlatTitle: function () {
      return this.editedIndex === -1 ? 'Добавить помещение' : 'Редактировать помещение'
    },
    formFloorTitle: function () {
      return this.editedFloorIndex === -1 ? 'Добавить этаж' : 'Редактировать этаж'
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('facility/GET_FACILITY_VIEW', this.facility_id)
        .then(response => {
          this.facility = response
        })
    },
    getEntranceArray (entrances) {
      const result = []
      entrances.forEach(function (item, idx, array) {
        result.push(item.number)
      })
      return result
    },
    clickSelectBlock (idx) {
      this.selectBlock = idx
    },
    flatTypeText (type) {
      return this.flatType.find(item => item.type === type).text
    },
    floorTypeText (type) {
      return this.floorType.find(item => item.type === type).text
    },
    changeFloor (item) {
      const payload = {
        guid: item.guid,
        floor_id: item.floor_id
      }
      this.$store.dispatch('facility/POST_FLOOR_FOR_FLAT', payload)
    },
    changeTypeFloor (item) {
      const payload = {
        guid: item.guid,
        type: item.type
      }
      this.$store.dispatch('facility/POST_FLOOR_TYPE_CHANGE', payload)
    },
    editItem (item) {
      this.editedIndex = this.flats.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.block_id = this.facility.block[this.selectBlock].id
      this.crudDialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.flats.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteDialog = true
    },
    viewFlatItem (item) {
      const payload = {
        type: this.flatType.find(({ type }) => type === item.type).text,
        block: this.facility.block.find(({ id }) => id === item.block_id).name,
        floor: this.floor.find(({ id }) => id === item.floor_id).number,
        name: item.name,
        area: item.area,
        bti: item.bti,
        description: item.description
      }
      this.$store.commit('rn/VIEW_FACILITY_FLAT_BY_ITEM', payload)
    },
    editFloorItem (item) {
      this.editedFloorIndex = this.floor.indexOf(item)
      this.editedFloorItem = Object.assign({}, item)
      this.floorDialog = true
    },
    crudAdd () {
      switch (this.facility.type) {
        case 'business':
        case 'trading':
        case 'street':
          this.editedItem.type = 'office'
          break
        case 'housing':
          this.editedItem.type = 'flat'
      }
      if (this.facility.block.length === 1) this.editedItem.block_id = this.facility.block[0].id
    },
    crudClose () {
      this.crudDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    crudSave () {
      this.crudDialog = false
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('facility/POST_FACILITY_FLAT', { id: this.facility_id, payload: this.editedItem })
        .then(response => {
          this.$set(this, 'facility', response)
          this.crudClose()
        }).catch(error => {
          const message = error.response.data.error || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    floorCrudClose () {
      this.floorDialog = false
      this.$nextTick(() => {
        this.editedFloorItem = Object.assign({}, this.defaultFloorItem)
        this.editedFloorIndex = -1
      })
    },
    floorCrudSave () {
      this.$store.commit('OVERLAY_ON')
      const rawData = JSON.stringify(this.editedFloorItem)
      const formData = new FormData()
      formData.append('plan', this.planImage)
      formData.append('data', rawData)
      this.$store.dispatch('facility/POST_FACILITY_FLOOR', { id: this.facility_id, formData: formData })
        .then(response => {
          this.$set(this, 'facility', response)
          this.flashMessage.success({ title: 'Сохранение.', message: 'Данные сохранены.' })
        }).catch(error => {
          const message = error.response.data.error || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        }).finally(() => {
          this.planImage = null
          this.$store.commit('OVERLAY_OFF')
        })
      this.floorCrudClose()
    },
    closeDelete () {
      this.deleteDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm () {
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('facility/DELETE_FACILITY_FLAT', { id: this.facility_id, params: this.flats[this.editedIndex].id })
        .then(response => {
          this.$set(this, 'facility', response)
        }).catch(error => {
          const message = error.response.data.message || ''
          const title = error.response.data.title
          this.flashMessage.error({ title, message })
        }).finally(() => { this.$store.commit('OVERLAY_OFF') })
      this.closeDelete()
    },
    openPlan (value) {
      this.showPlan = value
    },
    firstPartner (item) {
      if (item.s_object.length === 0) {
        return ''
      } else {
        return item.s_object[0].partner.name
      }
    },
    otherPartners (item) {
      if (item.s_object.length === 0) return []
      return item.s_object.slice(1)
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  watch: {
    crudDialog (val) {
      val || this.crudClose()
    },
    deleteDialog (val) {
      val || this.closeDelete()
    }
  }
}
</script>

<style scoped>

</style>
