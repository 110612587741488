<template>
  <v-dialog v-model="dialog" persistent max-width="560px">
    <v-form
      ref="time_form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>Рабочее время</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <span>Рабочее время</span>
              <time-picker v-model="time_data.start_time" label="Начало рабочего дня" clearable @click:clear="time_data.start_time = null"></time-picker>
              <time-picker v-model="time_data.end_time" label="Окончание рабочего дня" clearable @click:clear="time_data.end_time = null"></time-picker>
              <span>Обеденный перерыв</span>
              <time-picker v-model="time_data.start_break" label="Начало обеденного перерыва" clearable @click:clear="time_data.start_break = null"></time-picker>
              <time-picker v-model="time_data.end_break" label="Окончание обеденного перерыва" clearable @click:clear="time_data.end_break = null"></time-picker>
              <v-switch v-model="time_data.is_holiday_day" label="Выходной"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="error" @click="closeTimeDialog">Отменить</v-btn>
          <v-btn small color="success" @click="addTime">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TimePicker from '../../../components/TimePicker'
export default {
  name: 'WorkTimeForm',
  components: { TimePicker },
  props: {
    time_data: Object
  },
  data: () => ({
    valid: true
  }),
  computed: {
    dialog: function () {
      return this.$store.state.org.dialog.time
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    addTime () {
      if (!this.$refs.time_form.validate()) {
        return
      }
      this.$emit('pushTime')
      this.closeTimeDialog()
    },
    closeTimeDialog () {
      this.$store.commit('org/CLOSE_FORM', 'time')
    }
  }
}
</script>

<style scoped>

</style>
