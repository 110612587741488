var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-vuetify","inline":"","title":"Справочники"}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('v-tabs',{on:{"change":_vm.tabsChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.itemsTab),function(item){return _c('v-tab',{key:item.value},[_vm._v(_vm._s(item.name))])}),1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),([0, 1, 2].indexOf(_vm.tab)!==-1)?_c('v-dialog',{attrs:{"max-width":"560px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","small":"","dark":""}},'v-btn',attr,false),on),[_vm._v("Добавить")])]}}],null,false,2860008244),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(_vm.formComponent,{tag:"component",attrs:{"editedItem":_vm.editedItem}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.crudClose}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.crudSave}},[_vm._v("Сохранить")])],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.itemsTab),function(item){return _c('v-tab-item',{key:item.value},[(['group'].indexOf(item.value) === -1)?[_c('v-data-table',{attrs:{"headers":_vm.computedHeader,"items":_vm.items},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.params.no_reports",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",attrs:{"flat":""}},[(item.params.no_reports)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")]):_vm._e()],1)]}},{key:"item.params.manual",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.fieldManual(item)))])]}},{key:"item.params.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldType(item))+" ")]}}],null,true)})]:[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"headers":_vm.headersGroup,"items":_vm.itemsGroup,"single-select":"","show-select":""},on:{"input":_vm.inputClick,"click:row":_vm.rowClick},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSubgroup,"items":_vm.itemsSubgroup}})],1)],1)]],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }