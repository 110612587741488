<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Справочник объектов"
    >
      <v-row>
        <v-col cols="6">
          <v-data-table
            :headers="headers"
            :items="items"
          >
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="clickFacilityView(item.id)"><v-icon>mdi-eye</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">

        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'List',
  data: () => ({
    items: [],
    headers: [
      { text: 'Название', value: 'name' },
      { text: 'Адрес', value: 'address' },
      { value: 'action' }
    ]
  }),
  created () {
    this.getData()
    this.$eventHub.$on('add', this.createFacility)
    this.$eventHub.$on('refresh', this.getData)
  },
  destroyed () {
    this.$eventHub.$off('add', this.createFacility)
    this.$eventHub.$off('refresh', this.getData)
  },
  mounted () {
  },
  methods: {
    getData () {
      this.$store.dispatch('facility/GET_FACILITY')
        .then(response => {
          this.items = response
        })
    },
    createFacility () {
      this.$router.push({ name: 'FacilityCreate' })
    },
    clickFacilityView (id) {
      this.$router.push({ path: `/reference/facility/${id}/view` })
    }
  }
}
</script>

<style scoped>

</style>
