<template>
  <div>
    <v-card class="pa-3">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="city"
            :items="items1"
            :search-input.sync="search1"
            item-value="AOGUID"
            item-text="full_name"
            hide-no-data
            hide-selected
            return-object
            clearable
            label="Город или район"
            @change="change_city"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="np"
            :items="items2"
            :search-input.sync="search2"
            item-value="AOGUID"
            item-text="full_name"
            hide-no-data
            hide-selected
            return-object
            clearable
            label="Населенный пункт"
            :disabled="disable1"
            @change="change_np"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="street"
            :items="items3"
            :search-input.sync="search3"
            item-value="AOGUID"
            item-text="full_name"
            hide-no-data
            hide-selected
            return-object
            clearable
            label="Улица"
            :disabled="disable1"
            @change="change_street"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="house"
            :items="items4"
            item-value="HOUSEGUID"
            item-text="full_name"
            return-object
            :disabled="disable1"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="1">
          <v-btn small outlined text @click.stop="btnSelect" :disabled="!house">Выбрать</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'FiasPicker',
  data: () => ({
    city: undefined,
    np: undefined,
    street: undefined,
    house: undefined,
    items1: [],
    items2: [],
    items3: [],
    items4: [],
    queryTerm1: '',
    queryTerm2: '',
    queryTerm3: ''
  }),
  created () {
  },
  mounted () {
  },
  computed: {
    disable1: function () {
      return !this.city/* || this.city?.AOLEVEL === 4 */
    },
    disable2: function () {
      return !this.city
    },
    search1: {
      get: function () {
        return this.queryTerm1
      },
      set: function (val) {
        if (this.queryTerm1 !== val) {
          this.queryTerm1 = val
          this.loadAutocomplete1()
        }
      }
    },
    search2: {
      get: function () {
        return this.queryTerm2
      },
      set: function (val) {
        if (this.queryTerm2 !== val && val) {
          this.queryTerm2 = val || ''
          this.loadAutocomplete2()
        }
      }
    },
    search3: {
      get: function () {
        return this.queryTerm3
      },
      set: function (val) {
        if (this.queryTerm3 !== val) {
          this.queryTerm3 = val || ''
          this.loadAutocomplete3()
        }
      }
    }
  },
  methods: {
    loadAutocomplete1 () {
      if (!this.queryTerm1 || this.queryTerm1.length <= 2) {
        return
      }
      const payload = {
        term: this.queryTerm1 || ''
      }
      this.$store.dispatch('facility/GET_CITY', payload)
        .then(response => {
          this.items1 = response
        })
    },
    loadAutocomplete2 () {
      if (this.queryTerm2 === undefined || this.queryTerm2.length < 1) {
        return
      }
      const payload = {
        term: this.queryTerm2 || '',
        guid: this.city.AOGUID,
        type: 'np'
      }
      this.$store.dispatch('facility/GET_CITY', payload)
        .then(response => {
          this.items2 = response
        })
    },
    loadAutocomplete3 () {
      if (this.queryTerm3 === undefined || this.queryTerm3.length < 1) {
        return
      }
      const payload = {
        term: this.queryTerm3 || '',
        type: 'street'
      }
      if (this.city) {
        payload.guid = this.city.AOGUID
      }
      if (this.np) {
        payload.guid = this.np.AOGUID
      }
      this.$store.dispatch('facility/GET_CITY', payload)
        .then(response => {
          this.items3 = response
        })
    },
    change_city () {
      if (!this.city) {
        this.np = undefined
        this.street = undefined
        this.house = undefined
        this.$emit('btnSelect', { address: '', guid: '' })
      }
    },
    change_np () {
      if (!this.np) {
        this.street = undefined
      }
    },
    change_street () {
      if (this.street) {
        this.$store.dispatch('facility/GET_HOUSE', { guid: this.street.AOGUID })
          .then(response => {
            this.items4 = response
          })
      } else {
        this.items4 = undefined
      }
    },
    btnSelect () {
      const address = `${this.city.full_name}, ${(this.np) ? this.np.full_name + ', ' : ''}${this.street.full_name}, ${this.house.full_name}`
      this.$emit('btnSelect', { address: address, guid: this.house.HOUSEGUID })
    }
  }
}
</script>

<style scoped>

</style>
