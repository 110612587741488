<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-2 py-2"
      title="Изменить контрагента"
    >
      <partner-form :partner-data="partnerData" @saveData="saveData"></partner-form>
    </base-card>
  </v-container>
</template>

<script>
import PartnerForm from './PartnerForm'
export default {
  name: 'PartnerEdit',
  components: { PartnerForm },
  data: () => ({
    partnerData: undefined,
    partner_id: undefined
  }),
  created () {
    this.partner_id = this.$route.params.id
    this.getData()
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  methods: {
    getData () {
      this.$store.dispatch('partners/GET_PARTNER', this.partner_id).then(response => {
        this.partnerData = response
      })
    },
    saveData (item) {
      this.$store.commit('OVERLAY_ON')
      if (item.partners) delete item.partners
      if (item.tag || item.tag === '') delete item.tag
      this.$store.dispatch('partners/PUT_PARTNER', item).then(() => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Контрагент успешно сохранен.' })
        this.$router.push({ name: 'PartnersList' })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
