<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Добавление организации"
    >
      <org-form @saveData="saveData"></org-form>
    </base-card>
  </v-container>
</template>

<script>
import OrgForm from './OrgForm'
export default {
  name: 'OrgCreate',
  components: { OrgForm },
  data: () => ({
    // fields: {
    //   legal_form_id: null,
    //   name: '',
    //   okved: '',
    //   inn: '',
    //   kpp: '',
    //   phones: [''],
    //   email: '',
    //   web: '',
    //   leader_id: null,
    //   head_position: '',
    //   legal_address: '',
    //   actual_address: '',
    //   post_address: '',
    //   comment: '',
    //   bank_detail: [],
    //   operating_mode: [
    //     { dayofweek: 0, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 1, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 2, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 3, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 4, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 5, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
    //     { dayofweek: 6, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: true }
    //   ]
    // }
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  methods: {
    getItems () {
    },
    saveData ({ fields, create }) {
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('org/POST_ORG', fields).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Организация сохранена успешно.' })
        if (create) {
          this.$store.commit('partners/CREATE_ORG', response.org)
          this.$router.push({ name: 'PartnersCreate' })
        } else {
          this.$router.push({ path: `/reference/org/${response.org.id}/view` })
        }
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
