<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="$attrs" v-on="on" dense readonly prepend-icon="mdi-clock-time-four-outline" @click:clear="$emit('click:clear')"/>
    </template>
    <v-time-picker
      v-bind="$attrs"
      v-on="$listeners"
      @input="menu = false"
      format="24hr"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'time-picker',
  data: () => ({
    menu: false
  }),
  methods: {
  }
}
</script>
