<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      :title="orgFullName()"
    >
      <v-row>
        <v-col cols="7">
          <v-card class="px-3 py-2" elevation="1">
            <v-skeleton-loader :loading="Boolean($store.state.loading)" type="article" class="mx-auto">
              <v-row>
                <v-col cols="3"><span class="font-weight-bold mr-2">ОКВЭД:</span>{{ fields.okved }}</v-col>
                <v-col cols="3"><span class="font-weight-bold mr-2">ИНН:</span>{{ fields.inn }}</v-col>
                <v-col cols="3"><span class="font-weight-bold mr-2">КПП:</span>{{ fields.kpp }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><span class="font-weight-bold mr-2">Сайт:</span>{{ fields.web }}</v-col>
                <v-col cols="6"><span class="font-weight-bold mr-2">E-Mail:</span>{{ fields.email }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="d-flex flex-column">
                    <span class="font-weight-bold">Номера телефонов:</span>
                    <span
                      v-for="(num, idx) in fields.phones"
                      :key="idx"
                    ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ num }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><span class="font-weight-bold mr-2">Юридический адрес:</span>{{ fields.legal_address }}</v-col>
                <v-col cols="12"><span class="font-weight-bold mr-2">Фактический адрес:</span>{{ fields.actual_address }}</v-col>
                <v-col cols="12"><span class="font-weight-bold mr-2">Почтовый адрес:</span>{{ fields.post_address }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><span class="font-weight-bold mr-2">Руководитель:</span>{{ leaderFullName }}</v-col>
                <v-col cols="6"><span class="font-weight-bold mr-2">Должность руководителя:</span>{{ fields.head_position }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><span class="font-weight-bold">Комментарий:</span><br/>{{ fields.comment }}</v-col>
              </v-row>
            </v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="mb-3 px-3 py-2" elevation="1">
            <v-card-title class="pa-1 font-weight-light text-h6">Банковские реквизиты</v-card-title>
            <v-divider></v-divider>
            <v-skeleton-loader :loading="Boolean($store.state.loading)" type="article" class="mx-auto">
              <v-card-text>
                <template v-if="fields.bank_detail.length < 1">
                  <span>Данных нет</span>
                </template>
                <template v-else>
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                      v-for="(item, idx) in fields.bank_detail"
                      :key="idx"
                    >
                      <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <span class="font-weight-bold mr-2">Р/с:</span>{{ item.checking_account }}<br/>
                            <span class="font-weight-bold mr-2">К/с:</span>{{ item.corr_account }}<br/>
                            <span class="font-weight-bold mr-2">БИК:</span>{{ item.bik }}<br/>
                            <span class="font-weight-bold mr-2">Банк:</span>{{ item.bank_name }}
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-card-text>
            </v-skeleton-loader>
          </v-card>

          <v-card class="px-3 py-2" elevation="1">
            <v-card-title class="pa-1 font-weight-light text-h6">График работы</v-card-title>
            <v-divider></v-divider>
            <v-skeleton-loader :loading="Boolean($store.state.loading)" type="article" class="mx-auto">
              <v-card-text>
                <v-row
                  v-for="(wod, idx) in fields.operating_mode"
                  :key="idx"
                >
                  <v-col cols="1"><span class="text-uppercase">{{ $moment().isoWeekday(wod.dayofweek + 1).format('ddd') }}</span></v-col>
                  <template v-if="!wod.is_holiday_day">
                    <v-col cols="3">{{ wod.start_time || '...' }} - {{ wod.end_time || '...' }}</v-col>
                    <v-col cols="4"><span class="font-weight-black mr-2">Обед</span> {{ wod.start_break || '...' }} - {{ wod.end_break || '...' }}</v-col>
                  </template>
                  <template v-else>
                    <v-col cols="7">Выходной</v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'OrgView',
  data: () => ({
    org_id: null,
    fields: {
      legal_form_id: null,
      name: '',
      okved: '',
      inn: '',
      kpp: '',
      phones: [''],
      email: '',
      web: '',
      leader: {},
      head_position: '',
      legal_address: '',
      actual_address: '',
      post_address: '',
      comment: '',
      bank_detail: [],
      operating_mode: [
        { dayofweek: 0, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 1, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 2, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 3, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 4, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 5, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
        { dayofweek: 6, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: true }
      ]
    }
  }),
  created () {
    this.org_id = this.$route.params.id
    this.getItems()
    this.$eventHub.$on('back', this.goBack)
    this.$eventHub.$on('refresh', this.getItems)
    this.$eventHub.$on('edit', this.orgEdit)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
    this.$eventHub.$off('refresh', this.getItems)
    this.$eventHub.$off('edit', this.orgEdit)
  },
  mounted () {
  },
  computed: {
    phones_count: function () {
      return (this.fields.phones && this.fields.phones.length > 1) ? this.fields.phones.length : 1
    },
    leaderFullName: function () {
      // eslint-disable-next-line camelcase
      return this.fields.leader?.full_name
    }
  },
  methods: {
    getItems () {
      this.$store.dispatch('org/GET_LEGAL_FORM')
      this.$store.dispatch('org/GET_ORG_VIEW', this.org_id)
        .then(response => {
          this.fields = response
        })
    },
    orgFullName: function () {
      return (this.fields) ? `${this.fields.legal_form?.name} ${this.fields.name}` : ''
    },
    orgEdit: function () {
      this.$router.push({ path: 'edit' })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
