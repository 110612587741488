<template>
  <v-container
    id="data-tables"
    tag="section"
    fluid
  >
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Справочник организаций"
    >
      <v-row>
        <v-col cols="12">
          <v-filter
            :filters="filters"
            :filter-items="filterItems"
            @filterData="filterData"
            @setFilter="resetPage"
          ></v-filter>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="footerProps"
        item-key="id"
        dense
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              text icon color="accent" small dark class="mr-2"
              v-tooltip.bottom="`Просмотр`"
              @click.stop="$router.push({ path: `/reference/org/${item.id}/view` })"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              text icon color="accent" small dark class="mr-2"
              v-tooltip.bottom="`Редактировать`"
              @click.stop="$router.push({ path: `/reference/org/${item.id}/edit` })"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </base-card>
  </v-container>
</template>

<script>
import VFilter from '../../../components/VFilter'
import pagination from '../../../mixins/pagination'
export default {
  name: 'OrgList',
  components: { VFilter },
  mixins: [pagination],
  data: () => ({
  }),
  created () {
    this.getData()
    this.$eventHub.$on('add', this.createOrg)
    this.$eventHub.$on('refresh', this.getData)
  },
  destroyed () {
    this.$eventHub.$off('add', this.createOrg)
    this.$eventHub.$off('refresh', this.getData)
  },
  mounted () {
  },
  computed: {
    headers: function () {
      return [
        { value: 'legal_form.name', sortable: false },
        { text: 'Название', value: 'name' },
        { text: 'ИНН', value: 'inn' },
        { text: 'Руководитель', value: 'leader.short_name' },
        { text: 'Должность руководителя', value: 'head_position' },
        { sortable: false, value: 'actions' }
      ]
    },
    pagination: {
      get: function () {
        return this.$store.state.org.pagination
      },
      set: function (value) {
        this.$store.commit('org/SET_PAGINATION', value)
      }
    },
    filterItems: function () {
      return this.$store.state.org.filterItems
    },
    filters: {
      get: function () {
        return this.$store.state.org.filters
      },
      set: function (value) {
        this.$store.commit('org/SET_FILTERS', value)
      }
    }
  },
  methods: {
    getData () {
      this.filterData()
    },
    filterData () {
      this.setParams()
      this.$store.dispatch('org/GET_LIST', this.getParams).then(response => {
        this.items = response.org
        this.meta = response.meta
      })
    },
    createOrg () {
      this.$router.push({ name: 'OrgCreate' })
    }
  }
}
</script>

<style scoped>

</style>
