<template>
  <div>
    <v-form
      ref="org_form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" lg="7">
          <v-card class="px-4 py-4" elevation="1">
            <v-row>
              <v-col cols="3"><v-text-field v-model="fields.inn" dense label="ИНН" :rules="rulesInn" @input="setFlagInn"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col cols="4" lg="2">
                <v-select
                  v-model="fields.legal_form_id"
                  :items="legal_form"
                  item-value="id"
                  item-text="name"
                  dense
                  label="Форма"
                  :rules="ruleRequired"
                ></v-select>
              </v-col>
              <v-col cols="8" lg="10">
                <v-text-field
                  v-model="fields.name"
                  dense
                  label="Наименование организации"
                  :rules="ruleRequired"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><v-text-field v-model="fields.okved" dense label="ОКВЭД" :rules="rulesOkved"></v-text-field></v-col>
              <v-col cols="6"><v-text-field v-model="fields.kpp" dense label="КПП" :rules="rulesKpp"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><v-text-field v-model="fields.web" dense label="WEB-Сайт"></v-text-field></v-col>
              <v-col cols="6"><v-text-field v-model="fields.email" dense label="E-Mail" :rules="rulesEmail"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-masked-input
                  v-for="i in phones_count"
                  :key="i"
                  v-model="fields.phones[i-1]"
                  :label="`Телефон #${i}`"
                  dense
                  :mask="'+7 (###) ###-####'"
                >
                  <template v-slot:prepend>{{ `${i}.` }}</template>
                  <template v-slot:append-outer>
                    <v-btn icon color="primary" @click="addPhone">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="removePhone(i-1)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-masked-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><v-text-field v-model="fields.legal_address" dense label="Юридический адрес" :rules="ruleRequired"></v-text-field></v-col>
              <v-col cols="12"><v-text-field v-model="fields.actual_address" dense label="Фактический адрес" :rules="ruleRequired"></v-text-field></v-col>
              <v-col cols="12"><v-text-field v-model="fields.post_address" dense label="Почтовый адрес" :rules="ruleRequired"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete-multisearch
                  v-model="fields.leader_id"
                  item-value="id"
                  item-text="full_name"
                  label="Руководитель"
                  action="user/AUTOCOMPLETE_MEMBERS"
                  dense
                  :rules="ruleRequired"
                >
                  <template v-slot:append-outer>
                    <v-btn icon color="primary" @click="addMember">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete-multisearch>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="fields.head_position" dense label="Должность руководителя"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="fields.comment" dense label="Комментарий"></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card class="mb-3 px-3 py-2" elevation="1">
            <v-card-title class="pa-1 font-weight-light text-h6">Банковские реквизиты</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <template v-if="fields.bank_detail.length < 1">
                <span>Данных нет</span>
              </template>
              <template v-else>
                <v-expansion-panels focusable>
                  <v-expansion-panel
                    v-for="(item, idx) in fields.bank_detail"
                    :key="idx"
                  >
                    <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="10">
                          <span class="font-weight-bold mr-2">Р/с:</span>{{ item.checking_account }}<br/>
                          <span class="font-weight-bold mr-2">К/с:</span>{{ item.corr_account }}<br/>
                          <span class="font-weight-bold mr-2">БИК:</span>{{ item.bik }}<br/>
                          <span class="font-weight-bold mr-2">Банк:</span>{{ item.bank_name }}
                        </v-col>
                        <v-col cols="2">
                          <v-btn text icon color="accent" x-small dark class="mr-2"
                                 v-tooltip.bottom="`Редактировать`"
                                 @click.stop="editBank(idx)"><v-icon>mdi-pencil</v-icon></v-btn>
                          <v-btn text icon color="accent" x-small dark class="mr-2"
                                 v-tooltip.bottom="`Удалить`"
                                 @click.stop="deleteBank(idx)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn outlined small color="primary" @click="addBank">Добавить</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="px-3 py-2" elevation="1">
            <v-card-title class="pa-1 font-weight-light text-h6">График работы</v-card-title>
            <v-divider></v-divider>
            <v-card-text>

            </v-card-text>
            <v-row
              v-for="(wod, idx) in fields.operating_mode"
              :key="idx"
            >
              <v-col cols="1"><span class="text-uppercase">{{ $moment().isoWeekday(wod.dayofweek + 1).format('ddd') }}</span></v-col>
              <template v-if="!wod.is_holiday_day">
                <v-col cols="3">{{ wod.start_time || '...' }} - {{ wod.end_time || '...' }}</v-col>
                <v-col cols="4"><span class="font-weight-black mr-2">Обед</span> {{ wod.start_break || '...' }} - {{ wod.end_break || '...' }}</v-col>
                <v-col cols="1"><v-btn icon small v-tooltip.bottom="`Редактировать`" @click="addTime(idx)"><v-icon small>mdi-pencil</v-icon></v-btn></v-col>
                <template v-if="idx==0">
                  <v-col cols="1">
                    <v-btn icon small v-tooltip.bottom="`Скопировать до пятницы`" @click="copyByFriday">
                      <v-icon small>mdi-arrow-collapse-down</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon small v-tooltip.bottom="`Скопировать до воскресенья`" @click="copyBySunday">
                      <v-icon small>mdi-arrow-expand-down</v-icon>
                    </v-btn>
                  </v-col>
                </template>
              </template>
              <template v-else>
                <v-col cols="7">Выходной</v-col>
                <v-col cols="1"><v-btn icon small v-tooltip.bottom="`Редактировать`" @click="addTime(idx)"><v-icon small>mdi-pencil</v-icon></v-btn></v-col>
              </template>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn color="success" @click="saveDataAndCreatePartner" :disabled="!valid || isPartner" class="mr-3">Сохранить и создать контрагента</v-btn>
        <v-btn color="success" @click="saveData" :disabled="!valid">Сохранить</v-btn>
      </v-col>
    </v-row>
    <bank-form :bank_data="bank_data" @pushBank="pushBank"/>
    <work-time-form :time_data="time_data" @pushTime="pushTime"/>
    <member-form></member-form>
  </div>
</template>

<script>
import BankForm from './BankForm'
import WorkTimeForm from './WorkTimeForm'
import MemberForm from '../../../components/reference/MemberForm'
import VAutocompleteMultisearch from '../../../components/VAutocompleteMultisearch'
import VMaskedInput from '../../../components/VMaskedInput'
export default {
  name: 'OrgForm',
  components: { VMaskedInput, VAutocompleteMultisearch, MemberForm, WorkTimeForm, BankForm },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {
          legal_form_id: null,
          name: '',
          okved: '',
          inn: '',
          kpp: '',
          phones: [''],
          email: '',
          web: '',
          leader_id: null,
          head_position: '',
          legal_address: '',
          actual_address: '',
          post_address: '',
          comment: '',
          bank_detail: [],
          operating_mode: [
            { dayofweek: 0, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 1, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 2, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 3, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 4, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 5, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: false },
            { dayofweek: 6, start_time: null, end_time: null, start_break: null, end_break: null, is_holiday_day: true }
          ]
        }
      }
    }
  },
  data: () => ({
    dadata: [],
    isInnChanged: false,
    isPartner: false, // true - контрагент уже создан
    bank_idx: null,
    bank_data: {
      name: '',
      checking_account: '',
      corr_account: '',
      bik: '',
      bank_name: ''
    },
    time_idx: null,
    time_data: {
      dayofweek: null,
      start_time: null,
      end_time: null,
      start_break: null,
      end_break: null,
      is_holiday_day: false
    },
    defaultMember: {
      surname: '',
      name: '',
      patronymic: '',
      email: '',
      mobile: [],
      birthday: null,
      prior_comm: undefined
    },
    memberItems: [],
    valid: true,
    ruleRequired: [v => !!v || 'Обязательное поле'],
    rulesOkved: [
      v => v.length <= 10 || 'Длина ОКВЭД не более 10 символов',
      v => (/(\d{1,2}\.\d{1,2}\.\d{1,2}$|\d{1,2}\.\d{1,2}$)/.test(v) || v.length === 0) || 'ОКВЭД имеет формат XX.XX или XX.XX.XX'
    ],
    rulesInn: [
      v => !!v || 'Обязательное поле',
      v => (v && (v.toString().length === 10 || v.toString().length === 12)) || 'Длина ИНН 10 или 12 цифр',
      v => /\d{10}$/.test(v) || 'ИНН должен содержать 10 цифр'
    ],
    rulesKpp: [
      // v => !!v || 'Обязательное поле',
      v => ((v && v.toString().length === 9) || v.toString().length === 0) || 'Длина КПП 9 цифр',
      v => (/\d{9}$/.test(v) || v.toString().length === 0) || 'КПП должен содержать 9 цифр'
    ],
    rulesEmail: [
      // v => !!v || 'Обязательное поле',
      v => (/.+@.+\..+/.test(v) || v.toString().length === 0) || 'Некорректный E-Mail'
    ]
  }),
  created () {
    this.$eventHub.$on('onPostMember', this.onPostMember)
    this.loadData()
  },
  destroyed () {
    this.$eventHub.$off('onPostMember', this.onPostMember)
  },
  computed: {
    legal_form: function () {
      return this.$store.state.org.legal_form
    },
    phones_count: function () {
      return (this.fields.phones && this.fields.phones.length > 1) ? this.fields.phones.length : 1
    },
    inn_field: function () {
      return this.fields.inn
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('org/GET_LEGAL_FORM')
      this.$store.dispatch('user/GET_MEMBERS').then(response => {
        this.memberItems = response
      })
    },
    saveData () {
      if (!this.$refs.org_form.validate()) return
      this.$emit('saveData', { fields: this.fields, create: false })
    },
    saveDataAndCreatePartner () {
      if (!this.$refs.org_form.validate()) return
      this.$emit('saveData', { fields: this.fields, create: true })
    },
    setFlagInn () {
      this.isInnChanged = true
    },
    addBank () {
      this.$store.commit('org/OPEN_FORM', 'bank')
    },
    pushBank () {
      if (this.bank_data.id === undefined) {
        this.fields.bank_detail.push(this.bank_data)
      } else {
        this.$set(this.fields.bank_detail, this.bank_idx, this.bank_data)
      }
      this.bank_data = { name: '', checking_account: '', corr_account: '', bik: '', bank_name: '' }
    },
    editBank (idx) {
      this.bank_data = this.fields.bank_detail[idx]
      this.$store.commit('org/OPEN_FORM', 'bank')
    },
    deleteBank (idx) {
      this.fields.bank_detail.splice(idx, 1)
    },
    addTime (idx) {
      this.time_idx = idx
      this.time_data = { ...this.fields.operating_mode[idx] }
      this.$store.commit('org/OPEN_FORM', 'time')
    },
    pushTime () {
      this.$set(this.fields.operating_mode, this.time_idx, this.time_data)
    },
    addPhone () {
      this.fields.phones.push('')
    },
    removePhone (val) {
      if (this.phones_count > 1) this.fields.phones.splice(val, 1)
    },
    addMember () {
      this.editMember = this.defaultMember
      this.$store.commit('user/OPEN_DIALOG_MEMBER')
    },
    onPostMember (data) {
      this.memberItems = data.items
      this.fields.leader_id = data.user_id
    },
    getLegalId (val) {
      return this.legal_form.filter(element => element.name === val)
    },
    copyByFriday () {
      const self = this
      let monday
      let id
      this.fields.operating_mode.forEach(function (item, idx, array) {
        switch (item.dayofweek) {
          case 0:
            monday = item
            break
          case 1:
          case 2:
          case 3:
          case 4:
            id = self.fields.operating_mode[idx].id
            self.$set(self.fields.operating_mode, idx, { ...monday, dayofweek: idx, id: id })
            break
        }
      })
    },
    copyBySunday () {
      const self = this
      let monday
      let id
      this.fields.operating_mode.forEach(function (item, idx, array) {
        if (item.dayofweek === 0) {
          monday = item
        } else {
          id = self.fields.operating_mode[idx].id
          self.$set(self.fields.operating_mode, idx, { ...monday, dayofweek: idx, id: id })
        }
      })
    }
  },
  watch: {
    fields: async function (val) {
      this.isPartner = await this.$store.dispatch('org/GET_CHECK_PARTNER_ORG', val.id)
    },
    inn_field: function (val) {
      if (val.length === 10 && this.isInnChanged) {
        this.$store.dispatch('org/GET_ORG_BY_INN', val).then(response => {
          if (response !== false) {
            this.dadata = JSON.parse(response)
            this.fields.name = this.dadata.full_name
            this.fields.kpp = this.dadata.kpp
            this.fields.legal_address = this.dadata.legal_address
            this.fields.okved = this.dadata.okved
            // console.log(this.$store.getters.getLegalFormByName('ООО'))
            this.fields.legal_form_id = this.getLegalId(this.dadata.legal_form)[0].id
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
