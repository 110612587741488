<template>
  <v-container fluid>
    <v-form ref="partner_form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="8" class="pa-lg-1 pa-0">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="partnerData.type"
                    :items="partnerType"
                    :rules="ruleRequired"
                    label="Форма собственности"
                  ></v-select>
                  <v-text-field v-model="partnerData.name" label="Название"></v-text-field>
                  <v-text-field v-model="partnerData.name_additional" label="Дополнительное название"></v-text-field>
                  <v-autocomplete-multisearch
                    v-if="partnerData.type === 'entity'"
                    v-model="partnerData.org_id"
                    item-value="id"
                    item-text="name"
                    label="Контрагент Юридическое лицо"
                    clearable
                    action="org/AUTOCOMPLETE_ORG"
                  ></v-autocomplete-multisearch>
                  <v-autocomplete-multisearch
                    v-if="partnerData.type === 'individual'"
                    v-model="partnerData.user_id"
                    item-value="id"
                    item-text="full_name"
                    label="Контрагент Физическое лицо"
                    clearable
                    action="user/AUTOCOMPLETE_MEMBERS"
                  ></v-autocomplete-multisearch>
                  <v-select
                    v-model="partnerData.type_partner"
                    label="Тип контрагента"
                    multiple
                    dense
                    chips
                    deletable-chips
                    :items="type_partners"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-simple-table>
                    <thead>
                    <tr>
                      <th></th>
                      <th width="30%">Контактные лица</th>
                      <th width="65%">Роль</th>
                      <th class="text-right" />
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in partnerData.contact_person" :key="i">
                      <td>{{ `${i + 1}.` }}</td>
                      <td>
                        <span class="d-flex justify-space-between align-center">
                          <v-autocomplete-multisearch
                            v-model="item.user_id"
                            item-value="id"
                            item-text="full_name"
                            label="Контактное лицо"
                            avatar
                            action="user/AUTOCOMPLETE_MEMBERS"
                          ></v-autocomplete-multisearch>
                          <v-btn
                            v-if="!item.user_id"
                            icon
                            @click="addMember(i-1)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </span>
                      </td>
                      <td>
                        <v-text-field v-model="item.role" />
                      </td>
                      <td>
                        <v-btn icon color="error" outlined @click="remove_member(i-1)"><v-icon>mdi-minus</v-icon></v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                  <v-btn icon color="primary" outlined @click="add_member"><v-icon>mdi-plus-thick</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" lg="2">
                  <v-switch
                    v-model="partnerData.is_active"
                    label="Активен"
                  ></v-switch>
                </v-col>
                <v-col cols="6" lg="2">
                  <v-switch
                    v-model="partnerData.debtor"
                    label="Дебитор"
                    :disabled="!$auth.check('service')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="selectFlatTable"
                    :items="partnerData.service_object"
                    item-key="id"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                  >
                    <template v-slot:item.type="{ item }">
                      <span>{{ flatType[item.type] }}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn icon @click="deleteSelectedObject(item)"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" class="pa-lg-1 pa-0">
          <v-card class="pa-2">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="facility"
                    :items="facilityItem"
                    item-text="address"
                    item-value="id"
                    return-object
                    @change="changeFacility"
                    label="Объект"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6"></v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="filterSearch"
                    append-icon="mdi-magnify"
                    class="ml-auto"
                    label="Поиск"
                    clearable
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    v-model="service_object"
                    :headers="flatTable"
                    :items="flatItems"
                    :item-class="ownerFlat"
                    item-key="id"
                    :search.sync="filterSearch"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    show-select
                  >
                    <template v-slot:item.type="{ item }">
                      <span>{{ flatType[item.type] }}</span>
                    </template>
                    <template v-slot:item.partner="{ item }">
                      {{ firstPartner(item)}}
                      <v-menu
                        v-if="item.s_object.length > 1"
                        v-model="item.menu"
                        :close-on-content-click="false"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-menu-down-outline</v-icon></v-btn>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item
                              v-for="(i, key) in otherPartners(item)" :key="key"
                            >
                              <v-list-item-content>{{ i.partner.name }}</v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-btn small outlined color="success" @click="addServiceObject">
                  <v-icon left>mdi-arrow-left-thick</v-icon>Добавить
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn small color="error" @click="closeDialog" class="mr-2">Отменить</v-btn>
          <v-btn small color="success" :disabled="!valid" @click="saveData">Сохранить</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <member-form></member-form>
  </v-container>
</template>

<script>
import MemberForm from '../../../components/reference/MemberForm'
import VAutocompleteMultisearch from '../../../components/VAutocompleteMultisearch'
export default {
  name: 'PartnerForm',
  components: {
    VAutocompleteMultisearch,
    MemberForm
  },
  props: {
    partnerData: {
      type: Object,
      default: function () {
        return {
          type: undefined,
          name: '',
          name_additional: '',
          org_id: undefined,
          user_id: undefined,
          is_active: true,
          contact_person: [{ user_id: null, role: null }],
          service_object: [],
          type_partner: []
        }
      }
    }
  },
  data: () => ({
    partnerId: undefined,
    valid: true,
    facility: undefined,
    orgItem: [],
    memberItems: [],
    facilityItem: [],
    flatItems: [],
    service_object: [],
    // TODO развести две ниже переменные
    type_partners: [],
    partnerType: [
      { value: 'entity', text: 'Юридическое лицо' },
      { value: 'individual', text: 'Физическое лицо' }
    ],
    flatTable: [
      { text: 'Тип', value: 'type' },
      { text: 'Номер', value: 'name' },
      { text: 'Партнер', value: 'partner' }
    ],
    selectFlatTable: [
      { text: 'Адрес', value: 'address' },
      { text: 'Тип', value: 'type' },
      { text: 'Номер', value: 'name' },
      { value: 'action' }
    ],
    flatType: {
      flat: 'Квартира',
      not_residential: 'Нежилое',
      office: 'Офис',
      parking_place: 'Машиноместо',
      mop: 'МОП'
    },
    sortBy: 'name',
    sortDesc: false,
    filterSearch: '',
    ruleRequired: [v => !!v || 'Обязательное поле'],
    defaultMember: {
      surname: '',
      name: '',
      patronymic: '',
      email: '',
      mobile: [],
      birthday: null,
      prior_comm: undefined
    },
    indexEdit: null
  }),
  created () {
    this.partnerId = parseInt(this.$route.params.id)
    this.$eventHub.$on('onPostMember', this.onPostMember)
    this.getData()
  },
  destroyed () {
    this.$eventHub.$off('onPostMember', this.onPostMember)
  },
  computed: {
    member_count: function () {
      return (this.partnerData.contact_person === undefined) ? 0 : this.partnerData.contact_person.length
    },
    enrichedFlatItems: function () {
      return this.flatItems.map(x => ({ ...x, isSelectable: !x.s_object }))
    }
  },
  methods: {
    getData: function () {
      this.$store.dispatch('partners/GET_ORG_LIST').then(response => {
        this.orgItem = response.org
        this.type_partners = response.type_partners
      })
      this.$store.dispatch('user/GET_MEMBERS').then(response => { this.memberItems = response })
      this.$store.dispatch('facility/GET_FACILITY').then(response => { this.facilityItem = response })
      if (this.$store.state.partners.isAutoCreate) {
        this.partnerData = { ...this.partnerData, ...this.$store.state.partners.autoCreate }
        this.$store.commit('partners/RESET_AUTO_CREATE')
      }
    },
    saveData: function () {
      if (!this.$refs.partner_form.validate()) return
      this.$emit('saveData', this.partnerData)
    },
    closeDialog: function () {
      // this.$store.commit('partners/CLOSE_DIALOG')
      this.$router.push({ name: 'PartnersList' })
    },
    changeFacility () {
      this.$store.dispatch('facility/GET_FACILITY_FLAT', this.facility.id).then(response => { this.flatItems = response })
    },
    addServiceObject () {
      const self = this
      if (!this.facility) return
      this.service_object.forEach(function (item, i, array) {
        if (!self.partnerData.service_object.find(elem => elem.flat_id === item.id)) {
          self.partnerData.service_object.push({
            facility_id: item.facility_id,
            flat_id: item.id,
            address: self.facility.address,
            type: item.type,
            name: item.name
          })
        }
      })
    },
    deleteSelectedObject (item) {
      this.partnerData.service_object = this.partnerData.service_object.filter(elem => elem.flat_id !== item.flat_id)
    },
    addMember (index) {
      this.editMember = this.defaultMember
      this.indexEdit = index
      this.$store.commit('user/OPEN_DIALOG_MEMBER')
    },
    onPostMember (item) {
      this.partnerData.contact_person[this.indexEdit].user_id = item.user_id
      this.memberItems = item.items
    },
    add_member () {
      this.partnerData.contact_person.push({ role: '', user_id: null })
    },
    remove_member (val) {
      if (this.member_count > 1) this.partnerData.contact_person.splice(val, 1)
    },
    ownerFlat (item) {
      // const w = this.working.find(elem => elem.guid === response.guid)
      if (item.s_object && item.s_object.find(elem => elem.partner_id === this.partnerId)) {
        return 'grey lighten-1'
      }
    },
    firstPartner (item) {
      if (item.s_object.length === 0) {
        return ''
      } else {
        return item.s_object[0].partner.name
      }
    },
    otherPartners (item) {
      return item.s_object.slice(1)
    }
  }
}
</script>

<style scoped>

</style>
